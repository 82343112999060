// This is the image loader we will use to load images from the CMS.
// Next comes with a width that matches the screen size.
// Quality and format parameters are fixed values that are alwas appended to the image URL.
export default function imagesharpImageLoader({ src, width, quality }) {
  // Remove any query parameters by taking everything before the '?'
  const baseUrl = src.split('?')[0];
  let url = `${baseUrl}?width=${width}&quality=${quality || 75}`;

  // If the image is a .svg, we don't want to use the webp format parameter as it will cause an error
  if (!src.includes('.svg')) {
    url += `&format=webp`;
  }
  return url;
}
