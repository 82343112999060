import { Grid, Box } from '@ingeniorforeningen/figurine-core';
import {
  EmployeeCards,
  employeeCardsDeliveryApiParser,
  PageTop,
  RelatedContent,
} from '@ingeniorforeningen/figurine-cms';
import TableOfContents from '@/components/tableOfContents/TableOfContentsComponent';
import { LayoutProps } from '../types';
import styles from '../Layouts.module.css';
import { RelatedContentProps } from '../RelatedContent.helpers';

const InfoPageLayout = ({ children, properties, pageType }: LayoutProps) => {
  const hasFloater = Boolean(properties?.showTableOfContents);
  const relatedContentProps = RelatedContentProps(properties);
  const hasIdaExpert = properties?.employees?.items && properties?.employees?.items?.length > 0;

  return (
    <>
      <PageTop data={{ properties, contentType: pageType }} mt={hasFloater ? '4em' : undefined} />

      <Box className={styles.content} data-with-floater={hasFloater}>
        <Grid container m={0} p={0}>
          {children}
        </Grid>
        {hasFloater && (
          <Box mt="xl" mb="xl">
            <Box style={{ position: 'sticky', top: '5em' }}>
              <TableOfContents markupItems={properties?.components?.items || []} />
            </Box>
          </Box>
        )}
      </Box>

      {hasIdaExpert && (
        <EmployeeCards
          pb="xl"
          {...employeeCardsDeliveryApiParser({
            areas: [
              {
                items: properties?.employees?.items,
                alias: 'employees',
                rowSpan: 1,
                columnSpan: 12,
              },
            ],
            properties: {
              header: properties?.employeesSectionHeader,
            },
          })}
        />
      )}

      {relatedContentProps && <RelatedContent {...relatedContentProps} mt="xl" mb="xl" />}
    </>
  );
};

export default InfoPageLayout;
