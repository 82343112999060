import { TUmbracoNode } from '@/lib/api';
import {
  resolveIcon,
  resolveLink,
  resolveLinkedPageImage,
  TCardProps,
  TDeliveryApiItem,
  TRelatedContentProps,
} from '@ingeniorforeningen/figurine-cms';

const buildLink = (path: string) => ({
  href: path,
  text: '',
  target: '_self',
});

const createCardFromItem = (item: TUmbracoNode, variant: TCardProps['variant']): TCardProps => {
  const { contentType, properties } = item;
  const subject = (() => {
    switch (contentType) {
      case 'idadk_article_infoPage':
        return properties?.subject ? properties?.subject : properties?.articleType;
      case 'idadk_sectionFrontpage':
        return 'Tema';
      default:
        return '';
    }
  })();

  return {
    subject,
    header: properties?.header,
    text: properties?.metaDescription,
    variant,
    image:
      variant === 'image' ? resolveLinkedPageImage(item) : undefined,
    cta: item?.route?.path && buildLink(item?.route?.path),
  } as TCardProps;
};

export const RelatedContentProps = (
  properties: Record<string, TUmbracoNode> | undefined,
): TRelatedContentProps | undefined => {
  const cards: TRelatedContentProps['items'] = [];

  const firstRelatedItem = properties?.firstRelated?.[0];
  const secondRelatedItem = properties?.secondRelated?.[0];
  const hasContactBox = properties?.contactBox?.length > 0;

  if (firstRelatedItem) {
    cards.push(createCardFromItem(firstRelatedItem, 'spot'));
  }

  if (secondRelatedItem) {
    cards.push(createCardFromItem(secondRelatedItem, 'image'));
  }

  if (hasContactBox) {
    const item = properties?.contactBox?.[0]?.properties?.blocks?.items?.[0]?.content?.properties;
    cards.push({
      subject: item?.subject,
      header: item?.header,
      text: item?.text,
      cta: item?.link && resolveLink(item?.link?.[0]),

      contactList: item?.contactList?.items.map((contact: TDeliveryApiItem) => ({
        icon: resolveIcon(contact.content.properties?.ikon),
        text: contact.content.properties?.content,
        link:
          contact.content.properties?.link && resolveLink(contact.content.properties?.link?.[0]),
      })),
      variant: 'contact',
    });
  }

  return cards.length > 0 ? { items: cards } : undefined;
};
