// This should be fixed rather than silenced. But since this is for preview we don't prioritize it right now.
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  Button,
  Floater,
  Grid,
  Heading,
  Icon,
  Modal,
  Paper,
  Paragraph,
  showNotification,
} from '@ingeniorforeningen/figurine-core';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

const PreviewControls = () => {
  const { locale } = useRouter();
  const [guid, setGuid] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const idaDomains = ['ida', 'ida-ui', 'localhost', 'idaforsikring', 'insurance-ui'].join('|');
  const wrongDomainMessage = 'Gå til IDA.dk eller idaforsikring for at bruge linket.';
  const linkNotFoundMessage = 'Linket til Umbraco kunne ikke findes.';

  const editFnString = `
if (typeof window !== "undefined") {
  if (window.location.origin.match("${idaDomains}")) {
    const tag = "data-editlink";
    const editLink = document.querySelector(\`meta[\${tag}\`)?.getAttribute(tag);
    if (editLink) {
      window.open(editLink, "_blank");
    } else {
      alert("${linkNotFoundMessage}");
    }
  } else {
    alert("${wrongDomainMessage}");
  }
}
`;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tag = 'data-guid';
      const guidFromMeta = window.document
        .querySelector(`meta[${tag}]`)
        ?.getAttribute(tag) as string;
      setGuid(guidFromMeta);
      setPreviewUrl(`${window?.location?.origin}/api/preview?id=${guidFromMeta}&locale=${locale}`);
    }
  }, []);

  const copyPreviewToClipBoard = (event: SyntheticEvent<Element, Event>) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      const url = `${window?.location?.origin}/api/preview?id=${guid}&locale=${locale}`;
      navigator.clipboard.writeText(url);
      showNotification({
        text: 'Siden er gemt til udklipsholder',
        type: 'success',
      });
    }
  };

  const PreviewActions = (
    <>
      <Heading type="h3" text="Preview" variant="dark" />
      <Paragraph
        // eslint-disable-next-line max-len
        text="Du får vist upubliseret indhold, som ikke er tilgængeligt på sitet. Du kan trække 'Redigér side' knappen op på din bogmærkelinje for altid at kunne gå til siden i Umbraco."
        variant="dark"
      />
      <Grid container>
        <div
          style={{
            width: '100%',
            height: '3.5rem',
            borderRadius: '0.63em',
            backgroundColor: '#4a6283',
            color: 'white',
            display: 'flex',
            placeItems: 'center',
            justifyContent: 'center',
          }}
        >
          <a
            href={`javascript:${editFnString}`}
            style={{ color: 'white', textDecoration: 'none', fontWeight: '600' }}
          >
            Redigér i Umbraco
          </a>
          <Icon name="Edit" ml="xs" color="light" />
        </div>
        <Button onClick={copyPreviewToClipBoard} variant="default" text="Del side" icon="Send" />
        <Button
          href={`/api/exit-preview?guid=${guid}`}
          variant="default"
          text="Forlad preview"
          icon="DoorExit"
        />
        Se på mobil:
        <div style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={previewUrl}
            viewBox="0 0 256 256"
          />
        </div>
      </Grid>
    </>
  );

  return (
    <>
      <Floater position={{ left: '75%', right: '5%', bottom: '40%' }} visibleFrom="lg">
        <Paper style={{ width: '18em', display: 'grid', justifyItems: 'center', gap: '1em' }}>
          {PreviewActions}
        </Paper>
      </Floater>
      <Floater position={{ bottom: '20%', right: '10%' }} hiddenFrom="lg">
        <Button text="Preview" icon="Eye" onClick={() => setShowModal(!showModal)} />
        <Modal opened={showModal} onClose={() => setShowModal(false)}>
          {PreviewActions}
        </Modal>
      </Floater>
    </>
  );
};

export default PreviewControls;
