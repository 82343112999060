import DefaultPageLayout from './DefaultPageLayout';
import FrontPageLayout from './FrontPageLayout';
import SectionFrontPageLayout from './SectionFrontPageLayout';
import InfoPageLayout from './InfoPageLayout';
import { LayoutProps } from '../types';

export const resolveLayout = (type: string): React.ComponentType<LayoutProps> => {
  switch (type) {
    case 'idadk_infoPage':
      return InfoPageLayout;
    case 'idadk_frontpage':
      return FrontPageLayout;
    case 'idadk_sectionFrontpage':
      return SectionFrontPageLayout;
    default:
      return DefaultPageLayout;
  }
};
