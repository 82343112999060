import {
  PageTop,
  RelatedContent,
  EmployeeCards,
  employeeCardsDeliveryApiParser,
  simpleListDeliveryApiParser,
  SimpleList,
} from '@ingeniorforeningen/figurine-cms';
import { LayoutProps } from '../types';
import { RelatedContentProps } from '../RelatedContent.helpers';

const SectionFrontPageLayout = ({ children, properties, pageType, childNodeList }: LayoutProps) => {
  const relatedContentProps = RelatedContentProps(properties);
  const includeChildList = properties?.childNodeList && (childNodeList?.items?.length ?? 0) > 0;
  const includeImages = properties?.childNodeList_includeImages;

  const hasIdaExpert = properties?.employees?.items && properties?.employees?.items?.length > 0;

  return (
    <>
      <PageTop data={{ properties, contentType: pageType }} variant="classic" />
      <section>{children}</section>

      {hasIdaExpert && (
        <EmployeeCards
          pb="xl"
          {...employeeCardsDeliveryApiParser({
            areas: [
              {
                items: properties?.employees?.items,
                alias: 'employees',
                rowSpan: 1,
                columnSpan: 12,
              },
            ],
            properties: {
              header: properties?.employeesSectionHeader,
            },
          })}
        />
      )}
      {includeChildList && (
        <SimpleList
          {...simpleListDeliveryApiParser(
            childNodeList?.items || [],
            includeImages,
            properties?.childNodeList_header,
          )}
          mt="xl"
          mb="xl"
        />
      )}
      {relatedContentProps && <RelatedContent {...relatedContentProps} mt="xl" mb="xl" />}
    </>
  );
};

export default SectionFrontPageLayout;
