import {
  IApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
  PagedIApiContentResponseModel,
} from '@ingeniorforeningen/umbraco-client';
import { TBreadcrumbsProps } from '@ingeniorforeningen/figurine-core';
import tracker from 'src/gtm';
import { deliveryClient } from './api';
import { formatError } from './utilities';

const excludedContentTypes = [
  'idadk_websiteSettings',
  'idadk_websiteData',
  'idadk_reusableContentList',
  'idadk_relatedContentList',
  'idadk_relatedContentSet',
  'idadk_reusableContent',
  'idadk_relatedContaxtBox',
  'idadk_search',
  'idadk_referencePage',
  'shared_reusableComponent',
  'shared_reusableComponents',
  'shared_resuableComponentFolder',
];
const excludedContentTypesFilter = () => excludedContentTypes.map((type) => `contentType:!${type}`);

export const getBreadcrumbs = async ({
  path,
  locale,
}: {
  path: string;
  locale: string;
}): Promise<TBreadcrumbsProps['items']> => {
  const pathArray: string[] = path.split('/').filter((x) => x);
  let currentPath = '';

  const breadcrumbs: TBreadcrumbsProps['items'] = await Promise.all(
    pathArray.map(async (pathSegment: string) => {
      currentPath += `/${pathSegment}`;
      const currentNode = (await deliveryClient
        .getContentByPath({
          path: currentPath,
          accept_Language: locale,
          api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
          start_Item: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE,
        })
        .then((r) => r.toJSON() as IApiContentResponseModel)
        .catch((e: unknown) => {
          tracker.monitor?.exception({
            message: formatError(e).message,
            severityLevel: 3,
            properties: { message: 'Error fetching breadcrumbs' },
          });
        })) as IApiContentResponseModel | null;

      return {
        link: currentNode?.route?.path || '',
        label: currentNode?.name || '',
      };
    }),
  );

  return breadcrumbs;
};

export const getAllPages = async ({
  preview,
  locales,
  take = 999999,
}: {
  preview: boolean;
  locales: string[];
  take?: number;
}): Promise<(IApiContentResponseModel & { locale: string })[]> => {
  try {
    const allPages = (
      await Promise.all(
        locales?.map(async (locale) => {
          try {
            const pages = await deliveryClient
              .getContent({
                fetch: 'descendants:/',
                preview,
                start_Item: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE,
                api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
                accept_Language: locale,
                take,
                sort: ['level:asc'],
                filter: excludedContentTypesFilter(),
              })
              .then((r) => r.toJSON() as PagedIApiContentResponseModel);

            return pages.items
              .filter((page) => page.route)
              .map((page) => ({
                ...page,
                locale,
              }));
          } catch (e: unknown) {
            tracker.monitor?.exception({
              message: formatError(e).message,
              severityLevel: 3,
              properties: { message: `Error fetching all pages for language: ${locale}` },
            });
            return [];
          }
        }),
      )
    ).flat(1);

    return allPages as unknown as (IApiContentResponseModel & { locale: string })[];
  } catch (exception) {
    tracker.monitor?.exception({
      message: formatError(exception).message,
      severityLevel: 3,
      properties: { message: 'Error fetching all pages' },
    });
  }

  return [];
};

export const getChildNodes = async ({
  preview,
  currentNode,
}: {
  preview: boolean;
  currentNode: IApiContentResponseModel;
}) => {
  const childNodeList = (await deliveryClient
    .getContent({
      api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
      preview,
      start_Item: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE,
      fetch: `children:${currentNode?.id}`,
      filter: ['contentType:idadk_article_infoPage'],
      take: 9999,
      skip: 0,
      expand:
        // eslint-disable-next-line max-len
        'properties[image[all],employees[properties[choosenEmployee[all]]],contactBox[all],secondRelated[all],firstRelated[all],components[properties[component[properties[all]],choosenEmployee[properties[all]],person[properties[image]],programEntries[properties[speakers[properties[image]]]],image]]],properties[relatedContentCards[properties[all]],image[properties[all]],components[properties[image[properties[all]],component[properties[all]]]]]',
      fields: 'properties[contentTags,articleType,teaser,header,metaImage,image,date]',
    })
    .then((r) => r.toJSON() as PagedIApiContentResponseModel)
    .catch((e: unknown) => {
      tracker.monitor?.exception({
        message: formatError(e).message,
        severityLevel: 3,
        properties: { message: 'Error fetching child nodes' },
      });
      if (process.env.NEXT_PUBLIC_ENV === 'ci') {
        /* eslint no-console: 0 */
        console.log('Error fetching child nodes', e);
      }
    })) as PagedIApiContentResponseModel;

  return childNodeList;
};

export const getSettings = async ({
  locale,
  preview,
}: {
  path?: string;
  locale: string;
  preview: boolean;
}) => {
  const apiKey = process.env.NEXT_PUBLIC_UMBRACO_API_KEY;

  let siteSettingsNode = (await deliveryClient
    .getContentById({
      id: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE || '',
      accept_Language: locale,
      api_Key: apiKey,
      preview,
    })
    .then((r) => r.toJSON() as IApiContentResponseModel)
    .catch((e: unknown) => {
      tracker.monitor?.exception({
        message: formatError(e).message,
        severityLevel: 3,
        properties: { message: 'Error fetching settings' },
      });
    })) as IApiContentResponseModel | null;

  if (siteSettingsNode === undefined) {
    siteSettingsNode = null;
  }
  return siteSettingsNode;
};

export const getTaggedContent = async ({ preview }: { preview: boolean }) => {
  const allTaggedContent = (await deliveryClient
    .getContent({
      api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
      preview,
      start_Item: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE,
      fetch: `descendants:${process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE}`,
      filter: ['contentType:idadk_article_infoPage'],
      take: 9999,
      skip: 0,
      expand:
        // eslint-disable-next-line max-len
        'properties[image[all],employees[properties[choosenEmployee[all]]],contactBox[all],secondRelated[all],firstRelated[all],components[properties[component[properties[all]],choosenEmployee[properties[all]],person[properties[image]],programEntries[properties[speakers[properties[image]]]],image]]],properties[relatedContentCards[properties[all]],image[properties[all]],components[properties[image[properties[all]],component[properties[all]]]]]',
      fields: 'properties[contentTags,articleType,teaser,header,metaImage,image,date]',
    })
    .then((r) => r.toJSON() as PagedIApiContentResponseModel)
    .catch((e: unknown) => {
      tracker.monitor?.exception({
        message: formatError(e).message,
        severityLevel: 3,
        properties: { message: 'Error fetching tagged content' },
      });
    })) as PagedIApiContentResponseModel;

  return allTaggedContent;
};

export const getPageData = async ({
  path,
  locale,
  preview,
}: {
  path: string;
  locale: string;
  preview: boolean;
}) => {
  const settings = await getSettings({
    path,
    locale,
    preview,
  });

  const breadcrumbs = await getBreadcrumbs({ path, locale });

  const currentNodeParams = {
    accept_Language: locale,
    api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
    preview,
    start_Item: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE,
    expand:
      // eslint-disable-next-line max-len
      'properties[image[all],employees[properties[choosenEmployee[all]]],contactBox[all],secondRelated[$all],firstRelated[properties[image,header,teaser,metaTitle,metaDescription]],components[properties[component[properties[all]],choosenEmployee[properties[all]],person[properties[image]],programEntries[properties[speakers[properties[image]]]],image]]],properties[relatedContentCards[properties[all]],image[properties[all]],components[properties[image[properties[all]],component[properties[all]]]]]',
  };

  let currentNode = (await (preview && path.startsWith('/preview/')
    ? deliveryClient.getContentById({ id: path.split('/')[2], ...currentNodeParams })
    : deliveryClient.getContentByPath({ path, ...currentNodeParams })
  )
    .then((r) => r.toJSON() as IApiContentResponseModel)
    .catch((e: unknown) => {
      tracker.monitor?.exception({
        message: formatError(e).message,
        severityLevel: 3,
        properties: { message: 'Error fetching current node' },
      });
    })) as IApiContentResponseModel | null;

  if (currentNode?.contentType && excludedContentTypes.includes(currentNode.contentType)) {
    currentNode = null;
  }

  // If there is no metaImage or image on the current page, we need to fetch the frontpage node to get the image
  if (currentNode?.properties && !currentNode?.properties?.metaImage?.[0]) {
    if (currentNode?.properties?.image?.[0]) {
      currentNode.properties.metaImage = currentNode.properties.image;
    } else {
      const frontpageNode = (await deliveryClient
        .getContentByPath({
          path: '/',
          api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
          preview: false,
          start_Item: process.env.NEXT_PUBLIC_UMBRACO_FRONTPAGE,
          expand: 'properties[image[properties[all]]]',
        })
        .then((r) => r.toJSON() as IApiContentResponseModel)
        .catch((e: unknown) => {
          tracker.monitor?.exception({
            message: formatError(e).message,
            severityLevel: 3,
            properties: { message: 'Error fetching frontpage' },
          });
        })) as IApiContentResponseModel | null;

      currentNode.properties.metaImage = frontpageNode?.properties?.image;
    }
  }

  let childNodeList = {} as PagedIApiContentResponseModel;
  if (currentNode?.properties?.childNodeList) {
    childNodeList = await getChildNodes({ preview, currentNode });
  }

  let taggedNodes = {} as PagedIApiContentResponseModel;
  const taggedComponents = ['shared_taggedList'];
  const pageHasTaggedComponents = currentNode?.properties?.components?.items.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: Record<string, any>) => taggedComponents.includes(item?.content?.contentType),
  );

  if (pageHasTaggedComponents) {
    taggedNodes = await getTaggedContent({ preview });
  }

  return {
    page: { ...currentNode, childNodeList },
    settings,
    breadcrumbs,
    taggedNodes,
  };
};

export const calculateSoMeImageUrl = (props: IApiMediaWithCropsResponseModel[]) => {
  let result = '';
  const image = props?.[0];
  const params = [];

  if (image) {
    const { url } = image;
    const focal = image.focalPoint;

    result += url;

    if (image.crops && image.crops.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-shadow
      const crop = image.crops.find((crop: any) => crop.alias === 'Landskab') || image.crops[0];
      params.push(`width=${crop.width}`);
      params.push(`height=${crop.height}`);
    }
    if (focal) {
      params.push(`rxy=${focal.left},${focal.top}`);
    }

    params.push('format=jpg');
  }

  return `${result}?${params.join('&')}`;
};
