import {
  EmployeeCards,
  employeeCardsDeliveryApiParser,
  PageTop,
  RelatedContent,
} from '@ingeniorforeningen/figurine-cms';
import { LayoutProps } from '../types';
import { RelatedContentProps } from '../RelatedContent.helpers';

const DefaultPageLayout = ({ children, properties, pageType }: LayoutProps) => {
  const relatedContentProps = RelatedContentProps(properties);

  const hasIdaExpert = properties?.employees?.items && properties?.employees?.items?.length > 0;

  return (
    <>
      <PageTop data={{ properties, contentType: pageType }} />
      <section>{children}</section>

      {hasIdaExpert && (
        <EmployeeCards
          pb="xl"
          {...employeeCardsDeliveryApiParser({
            areas: [
              {
                items: properties?.employees?.items,
                alias: 'employees',
                rowSpan: 1,
                columnSpan: 12,
              },
            ],
            properties: {
              header: properties?.employeesSectionHeader,
            },
          })}
        />
      )}
      {relatedContentProps && <RelatedContent {...relatedContentProps} mt="xl" mb="xl" />}
    </>
  );
};

export default DefaultPageLayout;
