import { TUmbracoNode } from '@/lib/api';
import {
  TableOfContents as FigToC,
  HeaderTableOfContents,
  toId,
} from '@ingeniorforeningen/figurine-core';
import { load } from 'cheerio';
import React from 'react';
import tracker from 'src/gtm';

type TProps = {
  markupItems: TUmbracoNode[];
};

// In an Umbraco update the structure of the markup data was changed.
// This function is used to align the two data structurs.
function AlignMarkupData(markupItems: TUmbracoNode[]) {
  const existingStructure =
    markupItems[0]?.areas[0]?.items[0]?.content?.properties?.content?.markup;
  if (existingStructure) {
    return [existingStructure];
  }
  const newStructure: TUmbracoNode[] = [];
  markupItems.forEach((item) => {
    const markupItem = item.content?.properties?.content?.markup;
    if (markupItem) {
      newStructure.push(markupItem);
    }
  });
  return newStructure;
}

const TableOfContents = ({ markupItems }: TProps) => {
  const headings: { id: string; label: string }[] = [];
  try {
    const alignedMarkupItems = AlignMarkupData(markupItems);
    alignedMarkupItems.forEach((item) => {
      const titles = load(item)
        .html('h2')
        .split('</h2>')
        .filter((x) => x);
      titles.forEach((title) => {
        const label = title.replace('<h2 dir="ltr">', '').replace('<h2>', '').replace('&nbsp;', '');
        const id = toId(label);
        headings.push({ id, label });
      });
    });
  } catch (exception) {
    const message = 'Error in TableOfContents';
    tracker.monitor?.exception({
      message: 'Error in TableOfContents',
      severityLevel: 3,
      properties: { message, exception },
    });
  }
  return headings.length ? (
    <>
      <HeaderTableOfContents items={headings} hiddenFrom="md" />
      <FigToC items={headings} visibleFrom="md" />
    </>
  ) : null;
};

export default TableOfContents;
